import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image'
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useSpring, useTrail, animated, interpolate, useChain, useTransition } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Swiper from 'react-id-swiper/lib/ReactIdSwiper.full';
import { Autoplay } from 'swiper/dist/js/swiper.esm';
import '../../components/swiper.css';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import RichText from '@madebyconnor/rich-text-to-jsx';
import { useMediaQuery } from 'react-responsive'

// import RichText from '@madebyconnor/rich-text-to-jsx';


import * as Gs from '../../globals/styledComponents';

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import Row from "../../components/Row";
import ReactPlayer from "react-player";
import Footer from "../../components/footer";
import PanSlider from '../../components/utils/PanSlider';
import InfiniteSlider from "../../components/utils/InfiniteSlider";
import { StickyContainer, Sticky } from 'react-sticky';
import CustomLink from "../../components/utils/CustomLink";
import Logo from "../../components/svg/Logo";
import HeartSymbol from "../../components/svg/HeartSymbol";

const StyledSection = styled.section`

  ${tw`relative w-screen flex flex-wrap items-center mx-auto`};
  //  py-18 lg:py-44;
  background: ${props => props.grey ? "#F7F8FB" : "#fffff"};
  min-height: ${props => props.transition ? '22rem' : '38rem'};;
  @media (min-width: 992px){
    min-height: ${props => props.transition ? '40vh' : '90vh'};;
  }
  
`
const DesignThinkingSlide = styled.div`
  ${tw`mx-12`};
  h2{
    color: hsla(234, 52%, 90%, 0.5);
    ${tw`font-semibold`};
  }
`

const PanSliderContainer = styled.div`
  ${tw`relative w-screen max-w-screen overflow-hidden`};
  height: ${p => p.height ? p.height : '45vh'};
`

const PanSlide = styled.div`
  ${tw`flex items-center flex-no-wrap bg-blue pb-24`};
  touch-action: pan-y;
  -webkit-touch-action: pan-y;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  user-select: none;
  width: 3000px;
`

const MobileProductSection = styled(StyledSection)`
  ${tw`items-start lg:overflow-hidden`}; 
  height: auto;
  @media min-width(992px){
    max-height: 90vh;
  }

`

export default function Zyppys(props) {

  const data = useStaticQuery(graphql`query{
    seo: contentfulCaseStudyPage(slug : {eq: "zyppys"}){
      seo{
        title: pageTitle
        description: metaDescription
        author
        keywords
      }
    }
    main: contentfulCaseStudyPage(slug: {eq: "zyppys"}){
      hypotheses{
        priority
        users
        users
        image{
          file{
            url
          }
          description
        }
        body{
          json
        }
      }
      colorPalette{
        ... on ContentfulColor{
          name
          value
        }
      }
      client{
        logo{
          file{
            url
          }
        }
      }
    }
    stuff: contentfulCaseStudyPage(slug : {eq: "zyppys"}){
      heroTitle
      heroImage{
        fluid(quality: 100){
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      thumbnailImage{
        fluid(quality: 100){
          ...GatsbyContentfulFluid_tracedSVG
        }
      }  
    }
    intro:contentfulPageSection(slug:{eq:"zyppys-introduction-section"}){
      sectionHeading
      sectionParagraph{
        sectionParagraph
      }
      paragraphs{
        text{
          text
        }
      }
    }
    pivot:contentfulPageSection(slug:{eq:"zyppys-business-pivot"}){
      slug
      sectionHeading
      sectionParagraph{
        sectionParagraph
      }
    }
    soloHeading1:contentfulPageSection(slug:{eq:"zyppys-solo-section-heading-1"}){
      slug
      sectionHeading
    }
    hypothesesSection:contentfulPageSection(slug:{eq:"zyppys-product-hypotheses"}){
      slug
      sectionHeading
      sectionParagraph{
        sectionParagraph
      }
    }
    soloParagraph1:contentfulPageSection(slug:{eq:"zyppys-solo-section-paragraph-1"}){
      slug
      sectionParagraph{
        sectionParagraph
      }
    }
    soloHeading2:contentfulPageSection(slug:{eq:"zyppys-solo-section-heading-2"}){
      slug
      sectionHeading
    }
    brandIdentity:contentfulPageSection(slug:{eq:"zyppys-brand-identity"}){
      slug
      sectionHeading
      sectionSubHeading
      images{
        assets{
          fluid(quality:100){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      sectionParagraph{
        sectionParagraph
      }
      paragraphs{
        heading
        text{
          text
        }
      }
    }
    productDesign:contentfulPageSection(slug:{eq:"zyppys-product-design"}){
      slug
      sectionHeading
      sectionSubHeading
      images{
        assets{
            fluid(quality:100){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      sectionParagraph{
        sectionParagraph
      }
      paragraphs{
        heading
        subHeading
        text{
          text
        }
      }
    }
    mobileUsers:contentfulPageSection(slug:{eq:"zyppys-mobile-users"}){
      slug
      sectionHeading
      images{
        assets{
          fluid(quality:100){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    repeat:contentfulPageSection(slug:{eq:"zyppys-repeat"}){
      slug
      sectionHeading
      sectionParagraph{
        sectionParagraph
      }
      paragraphs{
        text{
          text
        }
      }
    }
    marketingPage:contentfulPageSection(slug:{eq:"zyppys-marketing-page"}){
      slug
      sectionHeading
      sectionSubHeading
      sectionParagraph{
        sectionParagraph
      }
      images{
        assets{
          fluid(quality:100){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    soloHeading3:contentfulPageSection(slug:{eq:"zyppys-solo-section-heading-3"}){
      slug
      sectionHeading
    }
    betterCAC:contentfulPageSection(slug:{eq:"zyppys-better-cac"}){
      slug
      sectionHeading
      sectionParagraph{
        sectionParagraph
      }
      images{
        assets{
          fluid(quality:100){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    other:contentfulPageSection(slug:{eq:"zyppys-other-improvements"}){
      slug
      sectionHeading
      images{
        assets{
          fluid(quality:100){
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      paragraphs{
        text{
          text
        }
      }
    }
    soloParagraph2:contentfulPageSection(slug:{eq:"zyppys-solo-section-paragraph-2"}){
      slug
      sectionParagraph{
        sectionParagraph
      }
    }
    end:contentfulPageSection(slug:{eq:"zyppys-end-section"}){
      slug
      sectionHeading
    }
    mobile:contentfulPageSection(slug: {eq: "zyppys-mobile-designs"}){
      sectionHeading
      images{
        assets{
          file{
            url
          }
        }
      }
    }
  }`
  )

  const swiperParams = {
    // spaceBetween: 10,
    modules: [Autoplay],
    // infinite: true,
    slidesPerView: 'auto',
    loopedSlides: 3,
    freeMode: true,
    freeModeMomentum: false,
    resistance: false,
    speed: 15000,
    loop: true,
    autoplay: {
      delay: 0,
    }

  }


  const isLarge = useMediaQuery({ query: `(min-width: 992px)` });
  const isMobileOrTablet = useMediaQuery({ query: `(max-width: 992px )` });


  const [hypState, setHypState] = useState(0);

  const hypothesesTrans = useTransition(hypState, p => p, {
    from: { y: 10, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -10, opacity: 0 },
    unique: true
  });


  return (

    <Layout withDarkMenu>
      {/* <link prefetch rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css" /> */}
      <SEO {...data.seo.seo} />

      <StyledSection css={`${tw`h-auto overflow-y-show z-0 relative`};`}>

        <Row css={`${tw``};padding-top: 30vh;`}>
          <div css={`${tw`w-full md:w-gr2/3 flex items-start`}`}>
            <div css={`${tw`mt-8 hidden md:inline-flex w-24 h-px bg-grey-light`}`}></div>
            <div css={`${tw`w-full`}`}>
              <h1 css={`${tw`-mt-6 flex-1 tracking-tight ml-2`}; line-height: 0.809;`}>
                {data.stuff.heroTitle}
              </h1>
              {/* <h2 css={`${tw`ml-3 mt-4 text-gr font-serif text-grey font-light`}`}>How we helped an Indian startup repurpose it's mission, build the country's first ever Rental Car Marketplace.</h2> */}
            </div>
          </div>
        </Row>

        {/* <Row stretch> */}

        <div css={`${tw`w-full mx-auto mt-8 lg:max-w-s-row`}`}>
          {isLarge ? <Img
            css={`${tw`w-full`}`}
            fluid={data.stuff.heroImage.fluid} /> :
            <Img css={`${tw`w-full my-4`}`}
              fluid={data.stuff.thumbnailImage.fluid} />
          }
        </div>

        {/* </Row> */}
        <div css={`
          ${tw`hidden lg:flex absolute w-full bg-grey-lightest h-screen z-back`}; 
          top: 0vh;
          @media (min-width: 992px) {
            top: 80.9vh;
          }
          `}>
        </div>

      </StyledSection>


      <StyledSection grey css={`${tw`py-40`}`}>


        <Row justifyBetween css={`${tw`items-start px-12`}`}>

          <div css={tw`w-gr2/3`}>
            <h2 css={`${tw`mb-12`}`}>{data.intro.sectionHeading}</h2>
          </div>

          <div css={`${tw`w-full flex flex-wrap mx-auto -mx-4 overflow-x-hidden`}`}>

            <div css={`${tw`w-full lg:w-gr1/3 px-4`}`}>

              <h2 css={`${tw`text-3xl font-serif font-light my-4 font-grey-lighter opacity-70`}`}>
                History
              </h2>
              <p css={`${tw`lg:text-xl`}`}>
                When our friends at zyppys first approached us, they were a two-year-old startup operating in 20+ cities. With a business that’s very similar to Uber India, zyppys was an online platform to rent Intercity chauffeur driven cars.
              </p>

            </div>
            <div css={`${tw`w-full lg:w-gr2/3 px-4`}`}>

              <h2 css={`${tw`text-3xl font-serif font-light my-4 font-grey-lighter opacity-70`}`}>
                Problem
              </h2>
              <p css={`${tw`lg:text-xl`}`}>
                After Uber India and OLA (Uber’s prime competitor in India) started penetrating deeper into the market, the car rental industry saw a significant change in the country. Consumer expectations from brands leveled up — good brand &amp; user experience became inevitable.
              </p>

            </div>
          </div>

          <div>

          </div>
        </Row>

      </StyledSection>


      <StyledSection css={`${tw`h-auto py-36`}`}>

        <Row css={`${tw`py-24 px-12 pb-36`}; border-bottom: 1px solid hsla(234, 15%, 20%, 0.2);`}>

          <div css={`${tw`lg:w-2/3 flex flex-wrap`}`}>

            <Gs.QuotationText css={`${tw`text-lg lg:text-xl tracking-wide leading-gr opacity-100 text-black`}`}>
              Even after Uber, OLA India, 80% of India’s rental car industry is still unorganized. Our vision is to play a leading role in organizing this $8B industry.
            </Gs.QuotationText>

            <img css={`${tw`h-12`}`}
              src={data.main.client.logo.file.url}
              alt={data.main.client.logo.description} />

          </div>

        </Row>
        <Row css={`${tw`py-24 px-12 pt-36 justify-end`};`}>

          <div css={`${tw`lg:w-2/3 flex flex-wrap text-right justify-end`}`}>

            <Gs.QuotationText css={`${tw`text-lg lg:text-xl tracking-wide leading-gr opacity-100 text-black`}`}>
              To be able to leapfrog an industry, it's not enough to work on superficial user experience. Renting cars is not a problem anymore; it's easy. Zyppys needs to re-think its core hypothesis and become something larger than Intercity cabs.
            </Gs.QuotationText>
            <Logo height="32" />
            {/* <img css={`${tw`h-12`}`}
              src={data.main.client.logo.file.url}
              alt={data.main.client.logo.description} /> */}

          </div>

        </Row>

      </StyledSection>

      <StyledSection grey transition>
        <Row>
          <Gs.TransitionTitle>
            Strategy
          </Gs.TransitionTitle>
          <div css={`${tw`flex-1 h-px bg-grey-light mt-4 ml-8 opacity-20`}`}></div>
        </Row>
      </StyledSection>


      {/* <StyledSection css={`${tw`lg:py-28`}`}>

        <Row css={`${tw`items-start justify-between px-12`}`}>

          <div css={`${tw`w-full mb-8 lg:w-1/2`}`}>
            <h3 css={`${tw`tracking-tight`}`}>
              Building to <span css={`border-bottom: 0.2rem solid; ${tw`border-blue`}`}>lead an industry?</span>
            </h3>
          </div>

          <div css={`${tw`w-full lg:w-1/2`}`}>
            <p>
              A superficial makeover often solves surface level problems; they are short lived. Renting cars online was no longer a serious problem for the consumer. Many competitors filled up the space. And evidently, holding a lion’s share in the industry would have been an uphill battle by purely offering the same service.
            </p>
            <p>
              Building a product first and then figuring out how to sell it is an inefficient and hyper risky way to build something. Instead, if we do the opposite — figure out the consumer problem and build an experience around it, it’d have a superior probability to succeed.            </p>
          </div>

        </Row>

      </StyledSection> */}

      <StyledSection css={`${tw`relative z-0 py-44`};`}>



        <div css={`${tw`flex flex-wrap items-start lg:max-w-row mx-auto w-full justify-between`}`}>

          <div css={`${tw`w-full max-w-row px-12 lg:px-2 lg:w-1/3 relative lg:pt-36 lg:sticky`}; top: 0;`}>
            <h3 css={`${tw`mb-8`}; line-height: 0.809;`}>
              Product Hypotheses.
            </h3>
            <p>
              After conducting several design sprints working closely with Zyppys's team, we have arrived at the following product hypotheses that felt like the right ideas to build Zyppys 2.0.
              This was based on existing user data, Interviewing customer support team, Competitive Analysis.
            </p>
            {/* <ul css={`${tw`list-reset m-0 mt-8`}`}>
              <li>Price comes first for Indian consumers</li>
              <li>Car rental companies are growing super fast</li>
              <li>Self driven rental cars have become a favorite among Indian millenials </li>
            </ul> */}
          </div>

          <div css={`${tw`w-full lg:w-2/3 relative z-0`}`}>


            {data.main.hypotheses.map((item, index) =>
              <Hypothesis
                {...item}
                key={index}
                dark={(index + 1) % 2 !== 0} />
            )}


          </div>

        </div>

        <div css={`${tw`absolute w-24 z-back h-px bg-grey-lightest pin-l`}; top: 12rem;`}></div>

      </StyledSection>

      <StyledSection>
        <Row justifyEnd>
          <div css={`${tw`w-full lg:w-1/2 px-4`}`}>
            <Gs.QuotationText>Final Call?</Gs.QuotationText>
            <h3 css={`${tw`mb-8`}`}>A One stop shop for renting all kinds of cars.</h3>
            <p css={`${tw`w-4/5`}`}>
              Zyppys changed to a one stop destination for all the car rental needs.
            </p>
          </div>
        </Row>
      </StyledSection>

      <StyledSection grey transition>
        <Row>
          <Gs.TransitionTitle>
            Execution
          </Gs.TransitionTitle>
          <div css={`${tw`flex-1 h-px bg-grey-light mt-4 ml-8 opacity-20`}`}></div>
        </Row>
      </StyledSection>

      <StyledSection css={tw`py-44`}>
        <Row css={tw`relative overflow-hidden items-start z-0 justify-between px-12`}>

          <div css={`${tw`w-full lg:w-1/4 flex items-center`}`}>
            <div css={`${tw`absolute hidden lg:inline-flex h-px bg-grey-light w-24`}; top: 25%; left: -6rem;`}></div>
            <h3 css={tw`w-auto`}>{data.brandIdentity.sectionHeading}</h3>
          </div>
          <div css={tw`w-full lg:w-1/2`}>

            <p>
              We tapped into cognitive science and asked: “How do we create a brand that sparks the same excitement in people’s minds when they think about a roadtrip?”
            </p>
            <p>
              The answer was to use some form of biomimicry: The colors and the patterns that we see in our natural environment, if replicated in digital environments, often tend to activate similar emotions.
            </p>
          </div>
        </Row>


        <Row css={`${tw`my-16 px-12`}`}>
          <div css={`${tw`px-2 w-full lg:w-1/2`}`}>
            <Gs.QuotationText>Sunset Sky</Gs.QuotationText>
            <img css={`${tw`w-full`}`} src={require('../../images/sunset-sky-inspiration-photo.png')} alt="By Shane Cotee-1276019 (www.unsplash.com)" />
            <div css={`${tw`flex items-center my-8`}`}>
              <div css={`height: 3rem; width: 3rem; background: #E60ED8; ${tw`rounded-full mr-8`}`}></div>
              <div css={`height: 3rem; width: 3rem; background: #621BC0; ${tw`rounded-full mr-8`}`}></div>
              <div css={`height: 3rem; width: 6rem; background-image: linear-gradient(27deg, #621BC0 0%, #E60ED8 100%); ${tw`rounded-full ml-14`}`}></div>
            </div>
          </div>
          <div css={`${tw`px-2 w-full lg:w-1/2 flex flex-wrap flex-col-reverse lg:flex-row`}`}>
            <div css={`${tw`flex items-center my-8`}`}>
              <div css={`height: 3rem; width: 3rem; background: #4C4D56; ${tw`rounded-full mr-8`}`}></div>
              <div css={`height: 3rem; width: 3rem; background: #F1EEEE; ${tw`rounded-full mr-8`}`}></div>
            </div>
            <img css={`${tw`w-full`}`} src={require('../../images/sunset-road-inspiration-photo.png')} alt="By Shane Cotee-1276019 (www.unsplash.com)" />
            <Gs.QuotationText css={`${tw`mt-8`}`}>Sunset Roads</Gs.QuotationText>
          </div>
        </Row>

        <Row>
          {/* <img css={tw`w-full`} src={require('../../images/zyppys-logo-presentation.svg')} /> */}
          <Img
            style={{ width: '100%' }}
            fluid={data.brandIdentity.images[0].assets[0].fluid} />
          <div css={tw`w-full lg:px-8`}>

            <div css={tw`w-full lg:w-4/5 px-4 lg:px-12 py-8 items-center mx-auto bg-grey-lightest flex flex-wrap`}>
              <div css={tw`w-full lg:w-1/2`}>
                <h3 css={`${tw`font-light text-base text-grey-light mb-4`}`}>Type Face</h3>
                <img css={`${tw`h-auto block`}`} src={require('../../images/zyppys-font-family.svg')} alt="Chromatica for Zyppys" />
                <img css={`${tw`h-auto block mt-8`}`} src={require('../../images/zyppys-font-family-heading.svg')} alt="Chromatica Heading for zyppys" />
                <img css={`${tw`h-auto block`}`} src={require('../../images/zyppys-font-family-paragraph.svg')} alt="Chromatica Paragraph for zyppys" />
                {/* <p>{data.brandIdentity.paragraphs[0].text.text}</p> */}
              </div>
            </div>

          </div>

        </Row>
      </StyledSection>

      <StyledSection>

        <Row css={tw`items-start justify-between overflow-hidden`}>

          <div css={`${tw`w-full lg:w-1/3`}`}>
            <h3 css={tw`w-auto mb-6`}>{data.productDesign.sectionHeading}</h3>

          </div>
          <div css={`${tw`w-full lg:w-1/2`}`}>
            <p>
              {data.productDesign.sectionParagraph.sectionParagraph}
            </p>
          </div>

        </Row>

      </StyledSection>

      <StyledSection css={tw`relative items-center h-auto`}>

        {/* <div css={`${tw`mx-auto`}`}> */}
        <Row>
          <div css={tw`w-full px-8 lg:w-1/2`}>

            <Gs.QuotationText small>
              The hardest part that we probably spent the most time upon, was to design a marketplace experience that doesn’t feel too busy and overwhelming like a marketplace.
              </Gs.QuotationText>

            <div css={tw`w-full`}>
            </div>

          </div>
        </Row>
        <Img
          // css={tw`-mt-32`}
          style={{ width: '100%', marginTop: 32 }}
          fluid={data.productDesign.images[0].assets[0].fluid} />

        {/* </div> */}

      </StyledSection>


      {/* Mobile Section */}
      <StyledSection css={`${tw`relative py-44`}; perspective: 2000px; -webkit-perspective: 2000px;`}>
        <Row css={tw`items-start`}>
          <div css={`${tw`lg:w-1/3 w-full lg:sticky`}; @media(min-width: 992px){top: 25vh;}`}>

            <div css={tw`w-full mb-8`}>

              {/* <div css={tw`h-px w-12 mt-8 inline-flex bg-grey-light mx-4`}></div> */}
              <h4 css={tw`w-3/4 lg:w-auto mb-8`}>{data.mobileUsers.sectionHeading}</h4>
              <p>Mobile users come with many nuanced differences compared to desktop users. Simply trying to fit everything onto a smaller screen wouldn't be the best user experience. We designed the experience keeping mobile usage in mind from day one — deeply understanding and respecting the usage patterns of smartphone users.</p>

            </div>

          </div>

          <div css={`${tw`flex flex-wrap items-center lg:text-center w-full lg:w-1/3 overflow-hidden relative`};`}>

            {/* <Img
              style={{ width: '100%', marginTop: -24 }}
              fluid={data.mobileUsers.images[0].assets[0].fluid} />
            <Img
              style={{ width: '100%', marginTop: 12 }}
              fluid={data.mobileUsers.images[0].assets[0].fluid} /> */}

            <ReactPlayer
              css={`${tw`appearance-none`}`}
              url={data.mobile.images[0].assets[1].file.url}
              height={640}
              style={{ position: 'relative', objectFit: 'cover', }}
              playing
              loop
              muted
              playsinline />
            <ReactPlayer
              css={`${tw`appearance-none`}`}
              url={data.mobile.images[0].assets[2].file.url}
              height={640}
              style={{ position: 'relative', objectFit: 'cover', }}
              playing
              loop
              muted
              playsinline />

          </div>
          <div css={`${tw`w-full lg:w-1/3 lg:sticky flex items-center overflow-hidden text-center`} @media(min-width: 992px){top: 5vh;}`}>
            {/* <img css={`${tw`w-full -mt-4`}`} src={require('../../images/zyppys-mobile-screenshot@2x.png')} /> */}
            {/* <Img
              style={{ width: '100%', marginTop: -4 }}
              fluid={data.mobileUsers.images[0].assets[0].fluid} /> */}
            <ReactPlayer
              css={`${tw`appearance-none`}`}
              url={data.mobile.images[0].assets[0].file.url}
              height={640}
              style={{ position: 'relative', objectFit: 'cover', }}
              playing
              loop
              muted
              playsinline />

          </div>

        </Row>
      </StyledSection>

      {/* <StyledSection css={`background-image: radial-gradient(at 0 0, #831FCC 0%, #781DC1 18%, #6C1BB5 35%, #6119AA 53%, #56179F 71%, #4B1594 89%);`}>
        <div css={tw`w-full`}>
          <Row css={tw`items-start`}>
            <div css={`${tw`w-full lg:w-gr1/3`}`}>
              <h3 css={`${tw`font-light text-white opacity-20`}`}>
                Constantly Improving
              </h3>
            </div>
            <div css={`${tw`w-full lg:w-gr2/3`}`}>
              <p css={tw`text-grey-lightest opacity-70`}>{data.repeat.sectionParagraph.sectionParagraph}</p>
            </div>
          </Row>

          <div css={`${tw`w-screen relative overflow-hidden flex flex-no-wrap`}; height:15rem; max-height: 15rem; `}>


            <Swiper className={`${tw`flex flex-no-wrap`}`}
              {...swiperParams}>

              <div css={`${tw`flex w-auto flex-no-wrap items-center justify-between`}; width: auto !important;`}>
                {data.repeat && data.repeat.paragraphs[0].text.text.split(' ').map((title) => (
                  <DesignThinkingSlide>
                    <h2>{title}</h2>
                  </DesignThinkingSlide>
                ))}
              </div>

            </Swiper>
          </div>
        </div>
      </StyledSection> */}


      {/* Marketing Page Section */}
      <StyledSection css={`${tw`py-44`}`}>

        <div css={`${tw`z-0 mx-4 lg:mx-16 bg-grey-lightest py-24 w-full flex flex-wrap relative overflow-visible`};`}>

          <Row css={`${tw`bg-green items-start`};`}>

            <div css={`${tw`relative flex flex-wrap w-full items-start justify-between `};`}>

              <div css={`${tw`relative lg:sticky w-full lg:w-1/3 px-4`}; @media(min-width: 768px){ top: 33vh;}`}>
                <h4>
                  {data.marketingPage.sectionHeading}<br />
                  {data.marketingPage.sectionSubHeading}
                </h4>

                <CustomLink external to="https://www.zyppys.com" css={tw`w-4/5 py-4 my-8`}>
                  <h6>Launch Project</h6>
                </CustomLink>

                {/* <p>
                  {data.marketingPage.sectionParagraph.sectionParagraph}
                </p> */}
              </div>


              <Img css={`${tw`w-full lg:w-2/3`};`}
                fluid={data.marketingPage.images[0].assets[0].fluid} />

            </div>


            {/* <div css={tw`w-full h-full flex justify-center text-center lg:w-gr2/3`}> */}
            {/* <img css={tw`w-full absolute pin-x mx-auto rounded-lg`} src={require('../../images/zyppys-marketing-page@2x.png')} /> */}
            {/* </div> */}

          </Row>
        </div>

      </StyledSection>


      {/* <StyledSection transition>
        <Row>
          <div css={tw`w-full lg:w-gr1/3`}>
            <Gs.TransitionTitle>
              {data.soloHeading3.sectionHeading}
            </Gs.TransitionTitle>
          </div>
        </Row>
      </StyledSection> */}


      <StyledSection css={tw`bg-black py-44`}>

        <Row css={`${tw`px-12`}`}>
          <h3 css={`${tw`text-grey-light lg:px-8 lg:w-gr2/3`}`}>So, how did everything go?</h3>
        </Row>

        <Row css={tw`py-44 px-12`}>

          <div css={`${tw`w-full lg:w-1/2 lg:px-12`}`}>
            <Img
              style={{ width: '100%', height: '100%' }}
              fluid={data.betterCAC.images[0].assets[0].fluid} />
          </div>
          <div css={tw`w-full lg:w-1/2 lg:px-8 my-12`}>
            <h4 css={tw`text-grey-light opacity-70 mb-8 w-2/3`}>{data.betterCAC.sectionHeading}</h4>
            <p css={tw`text-grey-light`}>{data.betterCAC.sectionParagraph.sectionParagraph}</p>
          </div>

        </Row>

        <Row css={tw`items-start px-12 py-44`}>

          <div css={`${tw`w-full lg:w-1/2 lg:px-8`}`}>
            <h4 css={tw`text-grey-light opacity-70 w-2/3`}>{data.other.sectionHeading}</h4>
            {/* <img css={`${tw`w-full`}`} src={require('../../images/zyppys-metrics_others.svg')} /> */}
            <Img
              style={{ width: '100%', height: '100%' }}
              fluid={data.other.images[0].assets[0].fluid} />
          </div>
          <div css={tw`w-full lg:w-1/2 lg:px-8`}>
            {data.other.paragraphs && data.other.paragraphs.map((para) => (
              <p css={tw`text-grey-light`}>{para.text.text}</p>
            ))
            }
          </div>

        </Row>


        <Row css={`${tw`lg:justify-end py-44 px-12`}; border-top: 1px solid hsla(234, 25%, 90%, 0.1);`}>

          <div css={tw`w-full lg:w-gr2/3`}>
            <Gs.QuotationText css={tw`text-grey-light`}>
              {data.soloParagraph2.sectionParagraph.sectionParagraph}
            </Gs.QuotationText>
          </div>

        </Row>

      </StyledSection>

      <StyledSection>
        <Row css={`${tw`px-12`}`}>
          <div css={tw`lg:w-gr2/3`}>
            <HeartSymbol css={`${tw`ml-2 `}`} height={48} />
            <Gs.QuotationText>So long, and so fun!</Gs.QuotationText>
            <p>
              Working with zyppys was fun and challenging. Currently, their in-house team usually is tweaking the product according to market inputs. We wish that they find the right kind of help in marketing this product so that it reaches its full potential.
            </p>
          </div>
        </Row>
      </StyledSection>

      <Footer />
    </Layout >

  );
}


const QuotationText = styled.p`
  ${tw`text-xl font-sans font-medium text-grey`};
`
const QuotationImageHolder = styled.div`
  ${tw`absolute p-6 rounded-full flex items-center justify-center`};
  border: 1px solid hsla(234, 10%, 60%, 0.2);
  width: 6rem;
  height: 6rem;
  left: -3rem;
  bottom: -3rem;
  &:before{
    content: "";
    display: block;
    height: 280%;
    width: 1px;
    background: hsla(234, 10%, 60%, 0.2);
    position: absolute;
    top: -280%;
  }
`

function QuotationComponent(props) {

  return (
    <div css={tw`relative w-full pt-32 pb-16 px-12`}>
      <QuotationText>
        {props.description}
      </QuotationText>
      <QuotationImageHolder>
        P
      </QuotationImageHolder>
    </div>
  );
}

function Color(props) {

  return (
    <div css={tw`relative flex flex-row-reverse lg:flex-row text-left justify-end items-center mb-4`}>
      <p css={tw`text-sm text-grey opacity-50 mx-4 inline-flex mb-0`}>{props.name}</p>
      <div css={`${tw``}`}>
        <div css={tw`h-8 min-w-8 w-8 rounded-full inline-block  `}
          style={{ background: `${props.value}` }}>
        </div>
      </div>
    </div>

  );
}


const HypothesesWrapper = styled(animated.div)`
  ${tw`relative flex flex-wrap items-stretch px-8 lg:px-8 py-12 lg:py-12 w-full overflow-hidden mt-12 mb-16`};
  
  // background: ${props => props.dark ? '#33343E' : '#D8DBF3'};
  box-shadow: 0 82px 70px -12px hsla(234, 5%, 60%, 0.40);
  ${props => {
    if (props.dark) {
      return 'background-image: linear-gradient(180deg, #3B3C44 0%, #23232A 100%);'
    }
    else {
      return 'background-image: linear-gradient(45deg, #F5EFFB 11 %, #DCD9DF 100 %);'
    }
  }}
  height: auto;
  @media(min-width: 992px){
    height: 80vh;
    border-radius: 1rem;

  }
 
`
const HypothesisIllustration = styled.img`
  height: 8rem;
  ${tw`px-4`};
  filter: drop-shadow(0 15px 30px rgba(10, 10, 20, 0.5));
`
const HypothesisLeft = styled.div`
  ${tw`relative z-10 w-full lg:w-1/4 flex lg:px-4 flex-wrap h-full`};
  
`

const Persona = styled.div`
  ${tw`lg:ml-6 w-auto`};
  h5{
    color: ${props => props.dark ? '#D8DBF3' : '#33343E'};
    ${tw`mb-4`}
  }
  h5::after{
    content: "";
    display: block;
    ${tw`h-px w-8 mt-2 mb-4`};
    background: ${props => props.dark ? '#D8DBF3' : '#33343E'}; 

  }
  p{
    color: ${props => props.dark ? '#D8DBF3' : '#33343E'}; 
    line-height: 1.05;
    font-size: ${Gs.modularScale * 1.5}rem;
    ${tw`font-serif font-light tracking-tight`};
    span{
      ${tw`block font-bold font-primary`};
    }
  } 
`

const HypothesisRight = styled.div`
  ${tw`w-full my-6 lg:w-3/4 md:pl-16 md:pr-12`};
  h3{
    font-size: ${Gs.modularScale * 1.5}rem;
    color: ${props => props.dark ? '#D8DBF3' : '#33343E'};
    ${tw`mb-4 font-serif font-light`}
  }
  // h3::after{
  //   content: "";
  //   display: block;
  //   ${tw`h-px w-8 mt-2 mb-4`};
  //   background: ${props => props.dark ? '#D8DBF3' : '#33343E'}; 

  // }
  p{
    color: ${props => props.dark ? '#D8DBF3' : '#33343E'}; 
  }
  ul{
    color: ${props => props.dark ? '#D8DBF3' : '#33343E'}; 
    li{
      
    }
  }
`


const Hypothesis = (props) => {



  return (

    <HypothesesWrapper dark={props.dark}
      style={{
        // opacity: props.opacity.interpolate(o => o),
        // transform: props.y.interpolate(y => `translate3d(0, ${y}px, 0)`),
      }}>

      <HypothesisLeft dark={props.dark} >

        <HypothesisIllustration src={props.image.file.url} />

        <Persona dark={props.dark}>
          <div css={`${tw`w-full`};`}>
            <h5 css={`line-height: 0.8;`}>
              <span css={tw`font-light opacity-70 block`}>{props.users.split(' ')[0]}</span>
              {props.users.split(' ')[1]}
            </h5>
            <p>
              Mostly rent cars for
              <span>leisure travel.</span>
            </p>
          </div>
        </Persona>

      </HypothesisLeft>

      <HypothesisRight dark={props.dark}>
        {/* {documentToHtmlString(props.body.json)} */}
        <RichText richText={props.body.json} />

      </HypothesisRight>


      <img css={`${tw`absolute w-auto h-auto z-0`}; @media( min-width: 992px){left:-20%; top: -10%};`}
        src={require('../../images/bluishBgPatterns.svg')}
        alt={'Clearcut Design'} />


    </HypothesesWrapper>

  );
}
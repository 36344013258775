import React, { useState, useEffect } from 'react';
import { useSpring, interpolate, animated } from 'react-spring';
import { add, scale } from 'vec-la';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useGesture } from 'react-use-gesture';
import clamp from 'lodash.clamp';
// import Measure from 'react-measure';
import useDimensions from 'react-use-dimensions';

const Wrapper = styled(animated.div)`
    ${tw`relative w-full`};
    height: ${p => p.heignt ? p.height : '20rem'};
    max-height: ${p => p.heignt ? p.height : '20rem'};    
`


const Slider = styled(animated.div)`
    ${tw`absolute w-auto h-full flex`};
    will-change: transform;
    touch-action: pan-x;
    -webkit-touch-action: pan-x;
    cursor: grab;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    user-select: none;
    
`


function PanSlider(props) {


    const [ref, { width }] = useDimensions();

    useEffect(() => {
        let wW = window.innerWidth;
        console.log(`Window Width : ${wW}px`);
    })

    const [{ x, y }, set, stop] = useSpring(() => ({ x: 0, y: 0 }));

    const bind = useGesture({
        onDrag: ({ down, delta, velocity, direction, temp = [x.getValue(), y.getValue()] }) => {
            console.log(`delta: ${delta} \ntemp: ${temp}`);
            debugger;
            set({
                x: clamp(temp[0] + delta[0], -width * 0.8, width * 0.8),
                y: clamp(temp[1] + delta[1], -100, 100),
                immediate: down,
                config: { velocity: velocity, decay: true }
            });
            return temp;
        }
    })

    return (

        <Wrapper ref={ref}>

            <Slider {...bind()}
                style={{
                    // transform: xy.interpolate((x, y) => `translate3d(${x}px, 0, 0)`),
                    transform: interpolate([x, y], (x, y) => `translate3d(${x}px,0,0)`)
                }}>

                {props.children}

            </Slider>

        </Wrapper>

    )
}

export default PanSlider;

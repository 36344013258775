import React, { useState, useEffect } from 'react';
import { useSpring, interpolate, animated } from 'react-spring';
import { add, scale } from 'vec-la';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { useGesture } from 'react-use-gesture';
import clamp from 'lodash.clamp';
// import Measure from 'react-measure';
import useDimensions from 'react-use-dimensions';

const Wrapper = styled(animated.div)`
    ${tw`relative w-full overflow-hidden`};
    height: ${p => p.heignt ? p.height : '20rem'};
    max-height: ${p => p.heignt ? p.height : '20rem'};    
`


const Slider = styled(animated.div)`
    ${tw`absolute w-auto h-full flex`};
    will-change: transform;
    user-select: none;
    
`


function InfiniteSlider(props) {

    // const [items, setItems] = useState(props.children);

    const [ref, { width }] = useDimensions();
    const [{ x, y }, set, stop] = useSpring(() => ({ x: 0, y: 0 }));
    let items = props.children;

    useEffect(() => {

        // console.log(`items: ${items.toLocaleString()}`);
        debugger;
        let wW = window.innerWidth;
        console.log(`Window Width : ${wW}px`);
        // set({
        //     to: async next => {
        //         while (1) {
        //             await next({ x: -width, y: 0 });
        //         }
        //     },
        //     onFrame: async () => {
        //         if (x = width / 2) {
        //             console.log('x is half of width now');
        //             // items = items.push[props.children];
        //             // items = items.pop[0];
        //         }
        //     },
        //     duration: 20000
        // });
    })


    return (

        <Wrapper ref={ref}>

            <Slider style={{
                transform: interpolate([x, y], (x, y) => `translate3d(${x}px,0,0)`)
            }}>

                {items}

            </Slider>

        </Wrapper>

    )
}

export default InfiniteSlider;
